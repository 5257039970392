export const bodyRegionList = [
  { id: 0, value: '', label: 'select.body.region' },
  { id: 1, value: 'ABDOMEN', label: 'Abdomen' },
  { id: 2, value: 'CARDIO_CIRCULATORY', label: 'Cardio-Circulatory' },
  { id: 3, value: 'CARDIOTHORACIC', label: 'Cardiothoracic' },
  { id: 4, value: 'MSK', label: 'MSK (Musculoskeletal)' },
  { id: 5, value: 'LUNG', label: 'Lung' },
  { id: 6, value: 'REPRODUCTIVE_SYSTEM', label: 'Reproductive System' },
  { id: 7, value: 'RESPIRATORY_SYSTEM', label: 'Respiratory System' },
  { id: 8, value: 'CNS', label: 'CNS (Central Nervous System)' },
  { id: 9, value: 'TISSUE', label: 'Tissue' },
];
