/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, DialogActions, Grid, Paper } from '@material-ui/core';

import useStyles from './styled/AdvancedToolFormMakeStyles';
import { Error, Form, Label } from '../Common/styled/Form';
import QuillEditor from '../Quill/QuillEditor';
import { imageModalityList } from '../../utils/categoryList';
import { medicalSpecialtyList } from '../../utils/medicalSpecialtyList';
import { bodyRegionList } from '../../utils/bodyRegionList';
import { advancedToolInterfaceType } from '../../utils/advancedToolUtils';

import { Dropzone } from '../Common/Dropzone/Dropzone';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { TextFieldUI } from '../../componentsUI/TextField';
import { TextFieldSelectUI } from '../../componentsUI/TextFieldSelect';
import { CheckBoxUI } from '../../componentsUI/CheckBox';
import { AlertUI } from '../../componentsUI/Alert';
import Loading from '../Common/Loading';

export const AdvancedToolForm = ({
  advancedTool,
  submitLabel,
  handleSubmit = () => {},
  onClose,
  serverError = false,
  submitting = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [keycloak] = useKeycloak();
  const isAdvancedToolAdmin = keycloak.tokenParsed.roles && keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/advanced.tools.admin');

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required.field')),
  });

  const initialValues = {
    name: advancedTool ? advancedTool.name : '',
    description: advancedTool ? advancedTool.description : '',
    category: advancedTool ? advancedTool.category : imageModalityList[0].value,
    visible: advancedTool ? advancedTool.visible : false,
    info1: advancedTool ? advancedTool.info1 || '' : '',
    info2: advancedTool ? advancedTool.info2 || '' : '',
    info3: advancedTool ? advancedTool.info3 || '' : '',
    info4: advancedTool ? advancedTool.info4 || '' : '',
    info5: advancedTool ? advancedTool.info5 || '' : '',
    speciality: advancedTool ? advancedTool.speciality : medicalSpecialtyList[0].value,
    bodyRegion: advancedTool ? advancedTool.bodyRegion : bodyRegionList[0].value,
    fdaCertification: advancedTool ? advancedTool.fdaCertification : false,
    ceCertification: advancedTool ? advancedTool.ceCertification : false,
    research: advancedTool ? advancedTool.research : false,
    creditCost: advancedTool ? advancedTool.creditCost : 0,
    endpoint: advancedTool ? advancedTool.endpoint : '',
    useIframe: advancedTool ? advancedTool.useIframe : '',
    feedbackUrl: advancedTool ? advancedTool.feedbackUrl : '',
    aeTitle: advancedTool ? advancedTool.aeTitle : '',
  };

  return (
    <Box className={classes.container}>
      <ScrollableContainer>
        <Paper elevation={2} className={classes.wrapper}>
          <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {(props) => (
              <Form autoComplete="off" onSubmit={props.handleSubmit} className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldUI label={t('name')} name="name" props={props} />
                  </Grid>

                  <Grid item xs={12}>
                    <Label htmlFor="description">{t('description')}</Label>
                    <QuillEditor
                      inForm
                      name="description"
                      formProps={props}
                      onChange={(html, text, form) => {
                        form.setFieldValue('description', text.trim().length ? html : '');
                      }}
                    />
                    <ErrorMessage name="description" component={Error} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldSelectUI
                      name="category"
                      label={t('category')}
                      props={props}
                      options={imageModalityList}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CheckBoxUI
                      name="visible"
                      label={t('visible')}
                      props={props}
                      checked={props.values.visible}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box className={classes.dropzone}>
                      <Label htmlFor="image">{t('image')}</Label>
                      <Dropzone
                        setFile={(image) => props.setFieldValue('image', image)}
                        clearFile={() => props.setFieldValue('image', null)}
                        currentFileUrl={advancedTool ? advancedTool.image : ''}
                      />
                      <ErrorMessage name="image" component={Error} />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box className={classes.dropzone}>
                      <Label htmlFor="reportModel">{t('report.model')}</Label>
                      <Dropzone
                        setFile={(pdf) => props.setFieldValue('reportModel', pdf)}
                        clearFile={() => props.setFieldValue('reportModel', null)}
                        currentFileUrl={advancedTool ? advancedTool.reportModel : ''}
                        accept="application/pdf"
                      />
                      <ErrorMessage name="reportModel" component={Error} />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Label htmlFor="info1">{t('benefits')}</Label>
                    <QuillEditor
                      inForm
                      name="info1"
                      formProps={props}
                      onChange={(html, text, form) => {
                        form.setFieldValue('info1', text.trim().length ? html : '');
                      }}
                    />
                    <ErrorMessage name="info1" component={Error} />
                  </Grid>

                  <Grid item xs={12}>
                    <Label htmlFor="info2">{t('application.and.diseases')}</Label>
                    <QuillEditor
                      inForm
                      name="info2"
                      formProps={props}
                      onChange={(html, text, form) => {
                        form.setFieldValue('info2', text.trim().length ? html : '');
                      }}
                    />
                    <ErrorMessage name="info2" component={Error} />
                  </Grid>

                  <Grid item xs={12}>
                    <Label htmlFor="info3">{t('diseases.list')}</Label>
                    <QuillEditor
                      inForm
                      name="info3"
                      formProps={props}
                      onChange={(html, text, form) => {
                        form.setFieldValue('info3', text.trim().length ? html : '');
                      }}
                    />
                    <ErrorMessage name="info3" component={Error} />
                  </Grid>

                  <Grid item xs={12}>
                    <Label htmlFor="info4">{`${t('info')} 4`}</Label>
                    <QuillEditor
                      inForm
                      name="info4"
                      formProps={props}
                      onChange={(html, text, form) => {
                        form.setFieldValue('info4', text.trim().length ? html : '');
                      }}
                    />
                    <ErrorMessage name="info4" component={Error} />
                  </Grid>

                  <Grid item xs={12}>
                    <Label htmlFor="info5">{`${t('info')} 5`}</Label>
                    <QuillEditor
                      inForm
                      name="info4"
                      formProps={props}
                      onChange={(html, text, form) => {
                        form.setFieldValue('info5', text.trim().length ? html : '');
                      }}
                    />
                    <ErrorMessage name="info5" component={Error} />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextFieldSelectUI
                      name="speciality"
                      label={t('specialty')}
                      props={props}
                      options={medicalSpecialtyList}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextFieldSelectUI
                      name="bodyRegion"
                      label={t('body.region')}
                      props={props}
                      options={bodyRegionList}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Label>{t('certifications')}</Label>
                    <div>
                      <CheckBoxUI
                        name="fdaCertification"
                        label={t('fdaCertification')}
                        props={props}
                        checked={props.values.fdaCertification}
                      />
                      <CheckBoxUI
                        name="ceCertification"
                        label={t('ceCertification')}
                        props={props}
                        checked={props.values.ceCertification}
                      />
                      <CheckBoxUI
                        name="research"
                        label={t('research')}
                        props={props}
                        checked={props.values.research}
                      />
                    </div>
                    <br />
                  </Grid>
                </Grid>

                {isAdvancedToolAdmin
                && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextFieldSelectUI
                        name="useIframe"
                        label={t('interface.type')}
                        props={props}
                        options={advancedToolInterfaceType}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextFieldUI label={t('credit.price')} name="creditCost" type="number" props={props} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldUI label={t('advanced.tool.endpoint')} name="endpoint" props={props} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldUI label={t('advanced.tool.feedback.url')} name="feedbackUrl" props={props} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldUI label={t('dicom.node.ae.title')} name="aeTitle" props={props} />
                    </Grid>
                  </Grid>
                )}

                {submitting && <Loading />}
                {serverError && (
                  <AlertUI severity="error" title={t('error')}>
                    {t('server.error')}
                  </AlertUI>
                )}

                <br />
                <DialogActions>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    disabled={submitting}
                    color="primary"
                    onClick={onClose}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.button}
                    disabled={submitting}
                    type="submit"
                    color="primary"
                  >
                    {submitLabel}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Paper>
      </ScrollableContainer>
    </Box>
  );
};
