export const categoryList = [
  { id: 1, value: 'CR', label: 'CR' },
  { id: 2, value: 'CT', label: 'CT' },
  { id: 3, value: 'ECG', label: 'ECG' },
  { id: 4, value: 'MG', label: 'MG' },
  { id: 5, value: 'MR', label: 'MR' },
  { id: 6, value: 'PET', label: 'PET' },
  { id: 7, value: 'SPECT', label: 'SPECT' },
  { id: 8, value: 'US', label: 'US' },
  { id: 9, value: 'XR', label: 'XR' },
  { id: 10, value: 'DR', label: 'DR' },
  { id: 11, value: 'OT', label: 'OT' },
];

export const imageModalityList = [
  { id: 1, value: 'CR', label: 'Computed Radiography (CR)' },
  { id: 2, value: 'CT', label: 'Computed Tomography (CT)' },
  { id: 3, value: 'ECG', label: 'Electrocardiography (ECG)' },
  { id: 4, value: 'MG', label: 'Mammography (MG)' },
  { id: 5, value: 'MR', label: 'Magnetic Resonance (MR)' },
  { id: 6, value: 'PET', label: 'Positron Emission Tomography (PET)' },
  { id: 7, value: 'SPECT', label: 'Single-photon Emission Tomography (SPECT)' },
  { id: 8, value: 'US', label: 'Ultrasound (US)' },
  { id: 9, value: 'XR', label: 'X-Ray (XR)' },
  { id: 10, value: 'DR', label: 'DR' },
  { id: 11, value: 'OT', label: 'Other (OT)' },
];
