import React from 'react';

import { AdvancedToolAddView } from '../../components/AdvancedTools/AdvancedToolAddView';
import Layout from '../../components/MenuLayout';

export default () => (
  <Layout type="provider">
    <AdvancedToolAddView />
  </Layout>
);
