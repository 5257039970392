/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';
import { navigate } from 'gatsby';

import { useMutation } from '@apollo/react-hooks';
import { Close } from '@material-ui/icons';
import { Container } from '@material-ui/core';

import { parseGraphqlErrors } from '../../utils/FormikUtils';
import Loading from '../Common/Loading';
import { GET_ADVANCED_TOOLS, CREATE_ADVANCED_TOOL } from '../../queries/AdvancedTools/AdvancedTools';
import { AdvancedToolForm } from './AdvancedToolForm';
import { ArticleContainerUI } from '../Common/styled/ArticleContainerUI';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

export const AdvancedToolAddView = () => {
  const { t } = useTranslation();
  const provider = useSelector((state) => state.provider);
  const [serverError, setServerError] = useState(false);

  const [keycloak] = useKeycloak();
  const isAdvancedToolAdmin = keycloak.tokenParsed.roles && keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/advanced.tools.admin');

  if (!provider) {
    return t('you.are.not.allowed.to.perform.this.action');
  }

  const [createAdvancedTool, { loading: updating }] = useMutation(
    CREATE_ADVANCED_TOOL,
    {
      onCompleted: () => {
        navigate('/advanced-tools');
      },
      refetchQueries: [{
        query: GET_ADVANCED_TOOLS,
        variables: { providerUuid: provider.uuid },
      }],
      awaitRefetchQueries: true,
    },
  );

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      const input = {
        providerUuid: provider.uuid,
        ...values,
      };
      await createAdvancedTool({ variables: { input } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const goBack = () => navigate('/dashboard');

  const buttons = [
    { name: 'go.back', icon: Close, handleClick: goBack },
  ];

  return (
    <ArticleContainerUI>
      <Navbar>
        <SectionBar
          title="add.tool"
          items={buttons}
        />
      </Navbar>
      {isAdvancedToolAdmin ? (
        <Container maxWidth="lg" className="article">
          <ScrollableContainer>
            {updating
              ? <Loading />
              : (
                <AdvancedToolForm
                  submitLabel={t('create')}
                  handleSubmit={handleSubmit}
                  serverError={serverError}
                  onClose={goBack}
                  submitting={updating}
                />
              )}
          </ScrollableContainer>
        </Container>
      ) : (
        <AlertWrapperUI>
          <AlertUI severity="warning" title={t('access.forbidden')}>
            {t('you.are.not.allowed.to.perform.this.action')}
          </AlertUI>
        </AlertWrapperUI>
      )}
    </ArticleContainerUI>
  );
};
